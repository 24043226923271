import VueLib from 'vue'
import merge from 'lodash.mergewith'
import * as Sentry from '@sentry/browser'
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations'

export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F40dedbf09aed4516ae6fe646ba26e96a@o156557.ingest.sentry.io\u002F5210221",
    environment:"uat",
    release:"fe92540aa5fc95e4a2147a4efe61f82a52bb6764",
    extra:{"url":"https:\u002F\u002Fuat-www.clifbar.com","deploy_url":"https:\u002F\u002F6759baa332780700072469ad--clifbar-uat.netlify.app"},
    ignoreErrors:["Network Error"],
    allowUrls:["https:\u002F\u002Fuat-www.clifbar.com"]
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  config.integrations = [
    new Dedupe({}),
    new ExtraErrorData({}),
    new ReportingObserver({}),
    new RewriteFrames({}),
    new Vue({ Vue: VueLib, ...{"attachProps":true,"logErrors":false}})
  ]

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config)

  inject('sentry', Sentry)
  ctx.$sentry = Sentry
}
